import React, { useState, useEffect, useRef, useCallback } from 'react';
import ReactHtmlParser from 'react-html-parser';
import {
  TextField,
  Box,
  Typography,
  IconButton,
  List,
  ListItem,
  Paper,
  Chip,
  Skeleton,
  Avatar,
  InputAdornment,
  Popper,
} from '@mui/material';
import { Dropdown, RedButton } from '../../../components/CommonComponent';
import ChatPrompt from './prompt';

// Icons
import { FaPaperPlane } from 'react-icons/fa';
import { GrPowerReset } from 'react-icons/gr';
import { PiThumbsUpDuotone, PiThumbsDownDuotone } from 'react-icons/pi';

// SCSS
import './product-chatbot.scss';

// Helpers
const enums = require('../../../enums');
const helper = require('../../../utils/helper');

// Constants
const API_KEY = process.env.REACT_APP_CHATBOT_API_KEY;
const dcMapping = {
  DC1: 'Taftville',
  DC2: 'Aberdeen',
  DC3: 'Shorewood',
  DC4: 'Carteret',
  DC5: 'San Bernardino',
};

const zones = [
  { zoneName: 'Default', zoneNumber: 1, zoneLabel: 'Zone 1' },
  { zoneName: 'Arizona', zoneNumber: 2, zoneLabel: 'Zone 2' },
  { zoneName: 'SoCal', zoneNumber: 3, zoneLabel: 'Zone 3' },
  { zoneName: 'NY Metro', zoneNumber: 4, zoneLabel: 'Zone 4' },
  { zoneName: 'New England', zoneNumber: 5, zoneLabel: 'Zone 5' },
  { zoneName: 'Kansas City / Des Moines', zoneNumber: 6, zoneLabel: 'Zone 6' },
  { zoneName: 'Southern VA', zoneNumber: 7, zoneLabel: 'Zone 7' },
  { zoneName: 'Chicago', zoneNumber: 8, zoneLabel: 'Zone 8' },
  { zoneName: 'Northern CA', zoneNumber: 9, zoneLabel: 'Zone 9' },
  { zoneName: 'Detroit', zoneNumber: 10, zoneLabel: 'Zone 10' },
];

// Utility function to escape HTML special characters
const escapeHTML = (str) =>
  str.replace(
    /[&<>'"]/g,
    (tag) =>
      ({
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        "'": '&#39;',
        '"': '&quot;',
      })[tag] || tag,
  );

// Utility function to validate URLs
const isValidUrl = (string) => {
  try {
    new URL(string);
    return true;
  } catch (_) {
    return false;
  }
};

// Function to format the bot response
const formatBotResponse = (response) => {
  let formattedResponse = escapeHTML(response);

  // Replace #### with <h5> tags (slightly smaller than h4)
  formattedResponse = formattedResponse.replace(/####\s(.*)/g, '<h5>$1</h5>');

  // Replace ### with <h4> tags
  formattedResponse = formattedResponse.replace(/###\s(.*)/g, '<h4>$1</h4>');

  // Replace **{text}** with <strong> tags
  formattedResponse = formattedResponse.replace(
    /\*\*\{(.*?)\}\*\*/g,
    '<strong>$1</strong>',
  );

  // Replace regular ** ** with <strong> tags
  formattedResponse = formattedResponse.replace(
    /\*\*(.*?)\*\*/g,
    '<strong>$1</strong>',
  );

  // Replace [text](link) with <a> tags, but only if the URL is valid
  formattedResponse = formattedResponse.replace(
    /\[(.*?)\]\((.*?)\)/g,
    (match, text, url) =>
      isValidUrl(url)
        ? `<a href="${url}" target="_blank" rel="noopener noreferrer">${text}</a>`
        : match,
  );

  // Replace newlines with <br> tags
  formattedResponse = formattedResponse.replace(/\n/g, '<br>');

  return formattedResponse;
};

// Utility function to format DC label
const formatDcLabel = (key) => `${key} - ${dcMapping[key]}`;

const ProductChatbot = ({
  isFullscreen,
  showChatPrompt,
  setShowChatPrompt,
  showSettings,
  setShowSettings,
}) => {
  // State management
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [isFirstOpen, setIsFirstOpen] = useState(true);
  const [uuid, setUuid] = useState('');
  const [chatBotLoading, setChatBotLoading] = useState(false);
  const [zipCode, setZipCode] = useState(localStorage.getItem('zip'));
  const [dcCode, setDcCode] = useState(() => {
    const dcValue = localStorage.getItem('dcList')?.split(',')[0];
    return (
      Object.keys(dcMapping).find((key) => key === dcValue) ||
      Object.keys(dcMapping).find((key) => dcMapping[key].includes(dcValue)) ||
      ''
    );
  });
  const [zoneCode, setZoneCode] = useState(
    localStorage.getItem('zone')
      ? zones.find(
          (z) => z.zoneNumber === parseInt(localStorage.getItem('zone')),
        ).zoneName
      : zones.find((z) => z.zoneNumber === 1).zoneName,
  );

  // Refs
  const textFieldRef = useRef(null);
  const userMessageRefs = useRef({});

  // Refs to keep track of previous zip and dc codes
  const prevZipCodeRef = useRef(zipCode);
  const prevDcCodeRef = useRef(dcCode);
  const prevZoneCodeRef = useRef(zoneCode);

  // Initialize chat function
  const initializeChat = useCallback(() => {
    const welcomeMessage = `Welcome! How can I assist you today? Please review your settings before we begin. <br/><br/> Zip Code: ${zipCode}<br/>DC: ${
      formatDcLabel(dcCode) ?? ''
    }<br/>Zone: ${zoneCode}`;
    setMessages([{ text: welcomeMessage, isBot: true }]);
    setUuid(helper.generateUUID()); // Use the improved generateUUID function
    setInputMessage('');
    localStorage.removeItem('chatbot_history');
    setShowChatPrompt(true);
    setIsFirstOpen(false);
  }, [zipCode, dcCode, zoneCode, setShowChatPrompt]);

  // Send message function
  const sendMessage = async (message, promptData = null) => {
    const messageToSend = message || inputMessage?.trim();
    setInputMessage('');

    if (messageToSend) {
      updateMessages(messageToSend, false);
      const payload = buildMessagePayload(messageToSend, promptData);
      await sendMessageToAPI(payload);
    }
  };

  // Update messages state
  const updateMessages = (text, isBot, uid) => {
    setMessages((prevMessages) => [
      ...prevMessages,
      { text, isBot, uid: uid ?? null },
    ]);
  };

  // Build message payload
  const buildMessagePayload = (message, promptData) => {
    const existingHistory =
      JSON.parse(localStorage.getItem('chatbot_history')) || [];
    const newMessage = {
      inputs: { chat_input: message },
      outputs: { chat_output: null },
    };
    existingHistory.push(newMessage);
    localStorage.setItem('chatbot_history', JSON.stringify(existingHistory));

    const payload = {
      user: helper.getEmplId(),
      zip: zipCode,
      dc: dcCode,
      zone: zones.find((z) => z.zoneName === zoneCode).zoneLabel || zoneCode,
      connectionId: uuid,
      message: {
        chat_history: existingHistory,
        chat_input: message,
      },
      output: { chat_output: null },
    };

    // Only add chatPrompt if it comes from the prompt component
    if (promptData) {
      payload.chatPrompt = promptData;
    }

    return payload;
  };

  // Get API DC & Zone
  const getZipToDC = async () => {
    try {
      const response = await fetch(
        helper.getAPIHost() +
          '/api/MDStoreDirectories/getZipToDC?zipCode=' +
          zipCode,
        {
          method: 'GET',
          ...helper.apiHeaders(),
        },
      );

      const data = await response.json();
      if (data.status === 'OK') {
        // Resolve the promise with the data
        return { success: true, result: data.result };
      } else {
        // Reject the promise with an error message
        return Promise.reject(data.errorMsg);
      }
    } catch (error) {
      // Reject the promise with the error message
      return Promise.reject('Error fetching DC and Zone: ' + error.message);
    }
  };

  // Send message to API
  const sendMessageToAPI = async (payload) => {
    setChatBotLoading(true);
    try {
      const response = await fetch(helper.getAPIHost() + '/SendMessage', {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json',
          Source: 'bobboost',
          'X-Api-Key': API_KEY,
        },
      });
      const data = await response.json();
      handleAPIResponse(data);
    } catch (error) {
      handleAPIError(error);
    } finally {
      setChatBotLoading(false);
      focusTextField();
    }
  };

  // Handle API response
  const handleAPIResponse = (data) => {
    if (data.status === 'OK') {
      const botResponse = data.result.output.chat_output;
      if (botResponse !== null) {
        const formattedResponse = formatBotResponse(botResponse);
        updateMessages(formattedResponse, true, data.result.outputId);
        updateLocalStorage(botResponse); // Store the original response
      } else {
        window.message.notification(
          'Unable to process response from Chatbot. Please try again.',
          enums.notificationType.E,
        );
      }
    } else {
      showErrorNotification(data);
    }
  };

  // Handle API error
  const handleAPIError = (error) => {
    window.message.notification(
      'Send Message API: ' + error.message,
      enums.notificationType.E,
    );
  };

  // Update local storage with bot response
  const updateLocalStorage = (botResponse) => {
    const chatHistory =
      JSON.parse(localStorage.getItem('chatbot_history')) || [];
    if (chatHistory.length > 0) {
      const lastMessage = chatHistory[chatHistory.length - 1];
      lastMessage.outputs.chat_output = botResponse;
      chatHistory[chatHistory.length - 1] = lastMessage;
      localStorage.setItem('chatbot_history', JSON.stringify(chatHistory));
    }
  };

  // Show error notification
  const showErrorNotification = (data) => {
    window.message.notification(
      `Failed to send message. Error Code: ${data.errorCode} Error message: ${data.errorMsg}`,
      enums.notificationType.E,
    );
  };

  // Focus text field after sending message
  const focusTextField = () => {
    setTimeout(() => {
      textFieldRef?.current?.focus();
    }, 500);
  };

  // Function to close the popover
  const handleClosePopover = () => {
    if (!zipCode) {
      window.message.notification(
        'Zip Code is required',
        enums.notificationType.E,
      );
      return;
    }

    // Check if zip or dc code has changed
    if (
      zipCode !== prevZipCodeRef.current ||
      dcCode !== prevDcCodeRef.current ||
      zoneCode !== prevZoneCodeRef.current
    ) {
      // Update all DC
      getZipToDC()
        .then((response) => {
          if (response?.success && response?.result !== null) {
            // Update previous values in refs
            prevZipCodeRef.current = zipCode;
            prevDcCodeRef.current = dcCode;
            prevZoneCodeRef.current = zoneCode;

            // Find the key in dcMapping by its value
            const mappedDcCode = Object.keys(dcMapping).find(
              (key) => key === response.result.primaryDCCode,
            );
            // Map the dcCode to its corresponding name using dcMapping
            const mappedZoneCode = zones.find(
              (x) => x.zoneNumber === parseInt(response.result.zoneNumber),
            ).zoneName;

            // Update current DC & Zone
            setDcCode(mappedDcCode);
            setZoneCode(mappedZoneCode);

            // Add bot message indicating the change of DC and Zip Code
            const botMessage = `Settings updated. <br/><br/>New Zip Code: ${zipCode}<br/>New DC: ${formatDcLabel(
              mappedDcCode,
            )}<br/>New Zone: ${mappedZoneCode}`;
            updateMessages(botMessage, true);

            setShowSettings(null);
          } else if (response.result === null) {
            window.message.notification(
              `Unable to find DC and Zone based on Zip Code ${zipCode}.`,
              enums.notificationType.E,
            );
          }
        })
        .catch((error) => {
          console.log(error);
          // Handle error notification
          window.message.notification(
            `Unable to find DC and Zone based on Zip Code ${zipCode}.`,
            enums.notificationType.E,
          );
        });
    } else {
      setShowSettings(null);
    }
  };

  const handleThumbs = async (msg, isThumbsUp) => {
    const uid = msg.uid; // Assuming each message has a unique ID
    let newThumbsUpValue;

    // Toggle logic
    if (isThumbsUp) {
      // If thumbs up is clicked
      newThumbsUpValue = msg.thumbsUp === 1 ? 0 : 1; // Toggle between 1 and 0
    } else {
      // If thumbs down is clicked
      newThumbsUpValue = msg.thumbsUp === 2 ? 0 : 2; // Toggle between 2 and 0
    }

    // Prepare the payload for the API call
    const payload = {
      uid: uid,
      messageLikeDislike: newThumbsUpValue,
    };

    try {
      const response = await fetch(
        helper.getAPIHost() + '/MessageLikeDislike',
        {
          method: 'POST',
          body: JSON.stringify(payload),
          headers: {
            'Content-Type': 'application/json',
            Source: 'bobboost',
            'X-Api-Key': API_KEY,
          },
        },
      );
      const data = await response.json();
      if (data.status === 'OK') {
        window.message.notification(
          `Record updated successfully`,
          enums.notificationType.S,
        );

        //Update messages state
        const updatedMessages = messages.map((message) => {
          if (message.uid === uid) {
            return { ...message, thumbsUp: newThumbsUpValue };
          }
          return message;
        });
        setMessages(updatedMessages);
      } else {
        window.message.notification(
          `Unable to update record.`,
          enums.notificationType.E,
        );
        console.error(
          `Error Code: ${data.errorCode} Error message: ${data.errorMsg}`,
        );
      }
    } catch (error) {
      console.error('Error in thumbs API call:', error);
    }
  };

  const handlePromptChat = (data) => {
    setInputMessage(data.prompt);
    sendMessage(data.prompt, data.chatPrompt);
    setShowChatPrompt(false);
  };

  // Initialize chat on first open
  useEffect(() => {
    if (isFirstOpen) {
      initializeChat();
    }
  }, [initializeChat, isFirstOpen]);

  // This effect is responsible for scrolling to the last user message in the chat window
  useEffect(() => {
    if (messages.length > 0) {
      const lastUserMessageIndex = messages.findLastIndex((msg) => !msg.isBot);
      // Check if the last message is from the user
      if (lastUserMessageIndex !== -1 && !messages[messages.length - 1].isBot) {
        userMessageRefs.current[lastUserMessageIndex]?.scrollIntoView({
          behavior: 'smooth',
        });
      }
    }
  }, [messages]);

  // Render components
  return (
    <>
      {/* Message List */}
      <Box className="chat-body">
        <List className="message-list">
          {messages.map((msg, index) => (
            <React.Fragment key={index}>
              <ListItem
                key={index}
                className={`message-item ${msg.isBot ? 'bot' : 'user'}`}
                ref={(el) => {
                  if (!msg.isBot) {
                    userMessageRefs.current[index] = el;
                  }
                }}
              >
                <Avatar className={`avatar ${msg.isBot ? 'bot' : 'user'}`}>
                  {msg.isBot ? (
                    <img src="/AI-Bot-Avatar.png" alt="Bot" />
                  ) : (
                    localStorage.getItem('ProfileInitial')
                  )}
                </Avatar>
                <Paper
                  elevation={1}
                  className={`message-bubble ${msg.isBot ? 'bot' : 'user'}`}
                >
                  {msg.isBot ? ReactHtmlParser(msg.text) : msg.text}
                </Paper>
              </ListItem>

              {/* Thumbs Up/Down Buttons up until the 5th bot message */}
              {msg.isBot && msg.uid > 0 && (
                <Box className="thumbs-box">
                  <IconButton
                    disabled={chatBotLoading}
                    onClick={() => handleThumbs(msg, true)} // Thumbs up
                    className={`thumbs-up-button ${
                      msg.thumbsUp === 1 ? 'active' : ''
                    }`}
                    title="Thumbs up"
                  >
                    <PiThumbsUpDuotone />
                  </IconButton>
                  <IconButton
                    disabled={chatBotLoading}
                    onClick={() => handleThumbs(msg, false)} // Thumbs up
                    className={`thumbs-down-button ${
                      msg.thumbsUp === 2 ? 'active' : ''
                    }`}
                    title="Thumbs down"
                  >
                    <PiThumbsDownDuotone />
                  </IconButton>
                </Box>
              )}
            </React.Fragment>
          ))}

          {/* Loading Skeleton */}
          {chatBotLoading && (
            <ListItem className="message-item bot">
              <Skeleton
                variant="circular"
                width={40}
                height={40}
                className="avatar bot skeleton"
              />
              <Skeleton
                variant="rounded"
                className="message-bubble bot"
                height={60}
              />
            </ListItem>
          )}
        </List>

        {/* New Session Option */}
        {messages.length > 1 && (
          <Box className="new-session-options">
            <Chip
              key="start-new"
              label="Start new session"
              disabled={chatBotLoading}
              onClick={() => setIsFirstOpen(true)}
              className="session-chip"
            />
          </Box>
        )}
      </Box>

      {/* Input Area */}
      <Box className="input-area">
        {showChatPrompt ? (
          <ChatPrompt
            isFullScreen={isFullscreen}
            onOptionSelect={handlePromptChat}
          />
        ) : (
          <TextField
            fullWidth
            variant="outlined"
            multiline
            minRows={isFullscreen ? 18 : 5}
            maxRows={isFullscreen ? 18 : 5}
            disabled={chatBotLoading}
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
            onKeyDown={(e) => e.key === 'Enter' && sendMessage()}
            autoComplete="off"
            inputRef={textFieldRef}
            placeholder="Type your message here..."
            InputProps={{
              endAdornment: (
                <IconButton
                  disabled={chatBotLoading}
                  onClick={() => sendMessage()}
                  className="send-button"
                >
                  <FaPaperPlane />
                </IconButton>
              ),
            }}
          />
        )}
      </Box>

      {/* Popover for managing zip code and DC code */}
      <Popper
        id={'manage-chatbot-setting'}
        open={Boolean(showSettings)}
        anchorEl={showSettings}
        placement="bottom-end"
        modifiers={[
          {
            name: 'flip',
            enabled: true,
          },
          {
            name: 'preventOverflow',
            enabled: true,
          },
          {
            name: 'offset',
            options: {
              offset: [-15, 15],
            },
          },
        ]}
        className="chatbot-setting"
      >
        <Paper>
          <Box padding={2}>
            <Typography variant="h6">Chatbot Settings</Typography>
            {/* Zip Code Setting */}
            <TextField
              label={
                <span>
                  Zip Code <span style={{ color: 'red' }}>*</span>
                </span>
              }
              value={zipCode}
              onChange={(e) => setZipCode(e.target.value)}
              fullWidth
              margin="normal"
              className="zip-code-setting"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      title="Reset"
                      onClick={() => setZipCode(localStorage.getItem('zip'))}
                    >
                      <GrPowerReset />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            {/* DC Code Setting */}
            <Dropdown
              id="dc-code-dropdown"
              placeholder="DC"
              value={{
                label: formatDcLabel(dcCode), // Using the utility function for formatting
                value: dcCode,
              }}
              options={Object.keys(dcMapping).map((key) => {
                return {
                  label: formatDcLabel(key), // Using the utility function for formatting
                  value: key,
                };
              })}
              isDisabled={true}
              onChange={(selectedOption) => setDcCode(selectedOption?.value)}
            />

            {/* Zone Code Setting */}
            <div style={{ marginTop: '1rem' }}>Pricing Zone</div>
            <Dropdown
              id="dc-code-dropdown"
              placeholder="Pricing Zone"
              value={{
                label: zoneCode,
                value: zoneCode,
              }}
              options={zones.map((x) => {
                return {
                  label: x.zoneName,
                  value: x.zoneNumber,
                };
              })}
              isDisabled={true}
              onChange={(selectedOption) => setZoneCode(selectedOption?.value)}
            />

            {/* Update button */}
            <RedButton
              onClick={handleClosePopover}
              label={'Update'}
              outline={true}
              customStyle={'update-setting-button'}
            />
          </Box>
        </Paper>
      </Popper>
    </>
  );
};

export default ProductChatbot;
