import React, { useState, useEffect, useRef, useCallback } from 'react';
import ReactHtmlParser from 'react-html-parser';
import {
  TextField,
  Box,
  IconButton,
  List,
  ListItem,
  Paper,
  Chip,
  Skeleton,
  Avatar,
} from '@mui/material';

// Icons
import { FaPaperPlane } from 'react-icons/fa';
import { PiThumbsUpDuotone, PiThumbsDownDuotone } from 'react-icons/pi';

// SCSS
import '../chatbot.scss';

// Helpers
const enums = require('../../../enums');
const helper = require('../../../utils/helper');

// Constants
const API_KEY = process.env.REACT_APP_CHATBOT_API_KEY;

// Utility function to escape HTML special characters
const escapeHTML = (str) =>
  str.replace(
    /[&<>'"]/g,
    (tag) =>
      ({
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        "'": '&#39;',
        '"': '&quot;',
      })[tag] || tag,
  );

// Utility function to validate URLs
const isValidUrl = (string) => {
  try {
    new URL(string);
    return true;
  } catch (_) {
    return false;
  }
};

// Function to format the bot response
const formatBotResponse = (response) => {
  let formattedResponse = escapeHTML(response);

  // Replace #### with <h5> tags (slightly smaller than h4)
  formattedResponse = formattedResponse.replace(/####\s(.*)/g, '<h5>$1</h5>');

  // Replace ### with <h4> tags
  formattedResponse = formattedResponse.replace(/###\s(.*)/g, '<h4>$1</h4>');

  // Replace **{text}** with <strong> tags
  formattedResponse = formattedResponse.replace(
    /\*\*\{(.*?)\}\*\*/g,
    '<strong>$1</strong>',
  );

  // Replace regular ** ** with <strong> tags
  formattedResponse = formattedResponse.replace(
    /\*\*(.*?)\*\*/g,
    '<strong>$1</strong>',
  );

  // Replace [text](link) with <a> tags, but only if the URL is valid
  formattedResponse = formattedResponse.replace(
    /\[(.*?)\]\((.*?)\)/g,
    (match, text, url) =>
      isValidUrl(url)
        ? `<a href="${url}" target="_blank" rel="noopener noreferrer">${text}</a>`
        : match,
  );

  // Replace newlines with <br> tags
  formattedResponse = formattedResponse.replace(/\n/g, '<br>');

  return formattedResponse;
};

const KnowledgeBaseChatbot = ({ isFullscreen }) => {
  // State management
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [isFirstOpen, setIsFirstOpen] = useState(true);
  const [uuid, setUuid] = useState('');
  const [chatBotLoading, setChatBotLoading] = useState(false);

  // Refs
  const textFieldRef = useRef(null);
  const userMessageRefs = useRef({});

  // Initialize chat function
  const initializeChat = useCallback(() => {
    const welcomeMessage = `Welcome to the Knowledge Base Assistant! How can I help you today?`;
    setMessages([{ text: welcomeMessage, isBot: true }]);
    setUuid(helper.generateUUID());
    setInputMessage('');
    localStorage.removeItem('kb_chatbot_history');
    setIsFirstOpen(false);
  }, []);

  // Send message function
  const sendMessage = async (message) => {
    const messageToSend = message || inputMessage?.trim();
    setInputMessage('');

    if (messageToSend) {
      updateMessages(messageToSend, false);
      const payload = buildMessagePayload(messageToSend);
      await sendMessageToAPI(payload);
    }
  };

  // Update messages state
  const updateMessages = (text, isBot, uid) => {
    setMessages((prevMessages) => [
      ...prevMessages,
      { text, isBot, uid: uid ?? null },
    ]);
  };

  // Build message payload
  const buildMessagePayload = (message) => {
    const existingHistory =
      JSON.parse(localStorage.getItem('kb_chatbot_history')) || [];
    const newMessage = {
      inputs: { chat_input: message },
      outputs: { chat_output: null },
    };
    existingHistory.push(newMessage);
    localStorage.setItem('kb_chatbot_history', JSON.stringify(existingHistory));

    return {
      user: helper.getEmplId(),
      connectionId: uuid,
      message: {
        chat_history: existingHistory,
        chat_input: message,
      },
      output: { chat_output: null },
    };
  };

  // Send message to API
  const sendMessageToAPI = async (payload) => {
    setChatBotLoading(true);
    try {
      const response = await fetch(helper.getAPIHost() + '/SendMessageKB', {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json',
          Source: 'bobboost',
          'X-Api-Key': API_KEY,
        },
      });
      const data = await response.json();
      handleAPIResponse(data);
    } catch (error) {
      handleAPIError(error);
    } finally {
      setChatBotLoading(false);
      focusTextField();
    }
  };

  // Handle API response
  const handleAPIResponse = (data) => {
    if (data.status === 'OK') {
      const botResponse = data.result.output.chat_output;
      if (botResponse !== null) {
        const formattedResponse = formatBotResponse(botResponse);
        updateMessages(formattedResponse, true, data.result.outputId);
        updateLocalStorage(botResponse);
      } else {
        window.message.notification(
          'Unable to process response from Knowledge Base Assistant. Please try again.',
          enums.notificationType.E,
        );
      }
    } else {
      showErrorNotification(data);
    }
  };

  // Handle API error
  const handleAPIError = (error) => {
    window.message.notification(
      'Send Message API: ' + error.message,
      enums.notificationType.E,
    );
  };

  // Update local storage with bot response
  const updateLocalStorage = (botResponse) => {
    const chatHistory =
      JSON.parse(localStorage.getItem('kb_chatbot_history')) || [];
    if (chatHistory.length > 0) {
      const lastMessage = chatHistory[chatHistory.length - 1];
      lastMessage.outputs.chat_output = botResponse;
      chatHistory[chatHistory.length - 1] = lastMessage;
      localStorage.setItem('kb_chatbot_history', JSON.stringify(chatHistory));
    }
  };

  // Show error notification
  const showErrorNotification = (data) => {
    window.message.notification(
      `Failed to send message. Error Code: ${data.errorCode} Error message: ${data.errorMsg}`,
      enums.notificationType.E,
    );
  };

  // Focus text field after sending message
  const focusTextField = () => {
    setTimeout(() => {
      textFieldRef?.current?.focus();
    }, 500);
  };

  const handleThumbs = async (msg, isThumbsUp) => {
    const uid = msg.uid;
    let newThumbsUpValue;

    // Toggle logic
    if (isThumbsUp) {
      newThumbsUpValue = msg.thumbsUp === 1 ? 0 : 1;
    } else {
      newThumbsUpValue = msg.thumbsUp === 2 ? 0 : 2;
    }

    // Prepare the payload for the API call
    const payload = {
      uid: uid,
      messageLikeDislike: newThumbsUpValue,
    };

    try {
      const response = await fetch(
        helper.getAPIHost() + '/MessageLikeDislikeKB',
        {
          method: 'POST',
          body: JSON.stringify(payload),
          headers: {
            'Content-Type': 'application/json',
            Source: 'bobboost',
            'X-Api-Key': API_KEY,
          },
        },
      );
      const data = await response.json();
      if (data.status === 'OK') {
        window.message.notification(
          `Record updated successfully`,
          enums.notificationType.S,
        );

        //Update messages state
        const updatedMessages = messages.map((message) => {
          if (message.uid === uid) {
            return { ...message, thumbsUp: newThumbsUpValue };
          }
          return message;
        });
        setMessages(updatedMessages);
      } else {
        window.message.notification(
          `Unable to update record.`,
          enums.notificationType.E,
        );
        console.error(
          `Error Code: ${data.errorCode} Error message: ${data.errorMsg}`,
        );
      }
    } catch (error) {
      console.error('Error in thumbs API call:', error);
    }
  };

  // Initialize chat on first open
  useEffect(() => {
    if (isFirstOpen) {
      initializeChat();
    }
  }, [initializeChat, isFirstOpen]);

  // This effect is responsible for scrolling to the last user message
  useEffect(() => {
    if (messages.length > 0) {
      const lastUserMessageIndex = messages.findLastIndex((msg) => !msg.isBot);
      if (lastUserMessageIndex !== -1 && !messages[messages.length - 1].isBot) {
        userMessageRefs.current[lastUserMessageIndex]?.scrollIntoView({
          behavior: 'smooth',
        });
      }
    }
  }, [messages]);

  // Render components
  return (
    <>
      {/* Message List */}
      <Box className="chat-body">
        <List className="message-list">
          {messages.map((msg, index) => (
            <React.Fragment key={index}>
              <ListItem
                key={index}
                className={`message-item ${msg.isBot ? 'bot' : 'user'}`}
                ref={(el) => {
                  if (!msg.isBot) {
                    userMessageRefs.current[index] = el;
                  }
                }}
              >
                <Avatar className={`avatar ${msg.isBot ? 'bot' : 'user'}`}>
                  {msg.isBot ? (
                    <img src="/AI-Bot-Avatar.png" alt="Bot" />
                  ) : (
                    localStorage.getItem('ProfileInitial')
                  )}
                </Avatar>
                <Paper
                  elevation={1}
                  className={`message-bubble ${msg.isBot ? 'bot' : 'user'}`}
                >
                  {msg.isBot ? ReactHtmlParser(msg.text) : msg.text}
                </Paper>
              </ListItem>

              {/* Thumbs Up/Down Buttons */}
              {msg.isBot && msg.uid > 0 && (
                <Box className="thumbs-box">
                  <IconButton
                    disabled={chatBotLoading}
                    onClick={() => handleThumbs(msg, true)}
                    className={`thumbs-up-button ${
                      msg.thumbsUp === 1 ? 'active' : ''
                    }`}
                    title="Thumbs up"
                  >
                    <PiThumbsUpDuotone />
                  </IconButton>
                  <IconButton
                    disabled={chatBotLoading}
                    onClick={() => handleThumbs(msg, false)}
                    className={`thumbs-down-button ${
                      msg.thumbsUp === 2 ? 'active' : ''
                    }`}
                    title="Thumbs down"
                  >
                    <PiThumbsDownDuotone />
                  </IconButton>
                </Box>
              )}
            </React.Fragment>
          ))}

          {/* Loading Skeleton */}
          {chatBotLoading && (
            <ListItem className="message-item bot">
              <Skeleton
                variant="circular"
                width={40}
                height={40}
                className="avatar bot skeleton"
              />
              <Skeleton
                variant="rounded"
                className="message-bubble bot"
                height={60}
              />
            </ListItem>
          )}
        </List>

        {/* New Session Option */}
        {messages.length > 1 && (
          <Box className="new-session-options">
            <Chip
              key="start-new"
              label="Start new session"
              disabled={chatBotLoading}
              onClick={() => setIsFirstOpen(true)}
              className="session-chip"
            />
          </Box>
        )}
      </Box>

      {/* Input Area */}
      <Box className="input-area">
        <TextField
          fullWidth
          variant="outlined"
          multiline
          minRows={isFullscreen ? 18 : 5}
          maxRows={isFullscreen ? 18 : 5}
          disabled={chatBotLoading}
          value={inputMessage}
          onChange={(e) => setInputMessage(e.target.value)}
          onKeyDown={(e) => e.key === 'Enter' && !e.shiftKey && sendMessage()}
          autoComplete="off"
          inputRef={textFieldRef}
          placeholder="Type your message here..."
          InputProps={{
            endAdornment: (
              <IconButton
                disabled={chatBotLoading}
                onClick={() => sendMessage()}
                className="send-button"
              >
                <FaPaperPlane />
              </IconButton>
            ),
          }}
        />
      </Box>
    </>
  );
};

export default KnowledgeBaseChatbot;
