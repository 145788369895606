import React from 'react';
import { NavLink, Redirect } from 'react-router-dom';
import { Collapse, Nav, NavItem, NavLink as BSNavLink } from 'reactstrap';
import { Drawer } from '@mui/material';

//Icons
import { GiHamburgerMenu } from 'react-icons/gi';
import * as menuIcons from 'react-icons/md';

//Utils
import bn from '../utils/bemnames';

//Assets
import * as menuIconsPNG from '../assets/img/menus';
import spacer from '../assets/img/spacer.png';

//Custom Component
import * as auth from '../application-center/login-center/lib/authentication';
import { OpportunityListSignalR } from '../bdf-applications/opportunity-list/opportunity-list-hub';
import { OpportunityProvider } from '../bdf-applications/opportunity-list/opportunity-context';

const helper = require('../utils/helper');
const ux = require('../application-center/ux-tracking-center');

// menu not from png, then use react icons
const menuIconSet = {
  menusetup: 'MdMenu',
  permsetup: 'MdPlaylistAddCheck',
  bucketsetup: 'MdDns',
  managebucket: 'MdDns',
  dynamicBucket: 'MdExtension',
  banner: 'MdNotificationsNone',
  //"F-maintenance": "MdSettings"
};

const bem = bn.create('sidebar');

class Sidebar extends React.Component {
  state = {
    menus: [],
    isAuthenticated: true,
    isLoading: true,
    isOpenSetup: false,
    enabledTouch: helper.getTouch(),
    adjusted: false,
    openDrawer: false,
    isOpen: false,
  };

  isShowByTitleID = () => {
    const BobSquidManagerView =
      localStorage.getItem('BobSquidManagerView') === 'true';
    const squadStoreId = localStorage.getItem('squadStoreId') === 'true';
    const isSalesChat = localStorage.getItem('isSalesChat') === 'true';

    // Return true if none of the job titles are valid
    return !(BobSquidManagerView || squadStoreId || isSalesChat);
  };

  async getMenus() {
    let isHomeOffice = localStorage.getItem('isHomeOffice') === 'true';

    await fetch(
      helper.getAPIHost() + '/api/getMenu/' + localStorage.getItem('EmplId'),
      helper.apiHeaders(),
    ).then(async (response) => {
      let menuSet = [];

      try {
        const data = await response.json();
        console.log('getMenu API');
        console.log(data);
        let menus_raw = data.result.menu;
        let sorted = menus_raw.sort(function (a, b) {
          let seqA = a.sequence;
          let seqB = b.sequence;
          if (seqA < seqB) return -1;
          if (seqA > seqB) return 1;
          return 0;
        });

        for (let m of sorted) {
          let tm = JSON.parse(JSON.stringify(m));
          if (tm.enabled && parseInt(tm.parentId) === 0) {
            if (typeof tm.menuIcon === 'string' && tm.menuIcon.trim() !== '') {
              tm.PNGMenu = menuIconsPNG[tm.menuIcon]
                ? menuIconsPNG[tm.menuIcon].default
                : null;
            } else if (menuIconSet[tm.menuCode]) {
              tm.MenuIcon = menuIcons[menuIconSet[tm.menuCode]];
            } else {
              tm.PNGMenu = spacer;
            }

            tm.menuCode = '/' + tm.menuCode;
            tm.pageTitle = tm.pageTitle || '';

            let subMenus = [];
            if (tm.menuType.toUpperCase() === 'F') {
              for (let sm of sorted) {
                let tsm = JSON.parse(JSON.stringify(sm));

                // if (tsm.menuCode === 'repeat') {//temporary put BCP-1802
                //   let selectedStore = [];
                //   let StoreId = localStorage.getItem('StoreId')
                //   selectedStore = [172,174,250,252,254,278,286,338,138,142,144,145,146,168,170,178,180,304,306]
                //   if(selectedStore.indexOf(parseInt(StoreId)) > -1){
                //     console.log("ada")
                //   }else{
                //     console.log("xada")
                //     tsm.enabled = false
                //   }
                // }
                if (tsm.enabled && tsm.parentId === tm.uid) {
                  if (
                    typeof tsm.menuIcon === 'string' &&
                    tsm.menuIcon.trim() !== ''
                  ) {
                    tsm.PNGMenu = menuIconsPNG[tsm.menuIcon]
                      ? menuIconsPNG[tsm.menuIcon].default
                      : null;
                  } else if (menuIconSet[tsm.menuCode.replace('/', '')]) {
                    tsm.MenuIcon = menuIcons[menuIconSet[tsm.menuCode]];
                  } else {
                    tsm.PNGMenu = spacer;
                  }
                  tsm.menuCode = '/' + tsm.menuCode;
                  tsm.pageTitle = tsm.pageTitle || '';

                  if (
                    tsm.menuCode === '/opportunity' &&
                    !this.isGuestConnectPermissible() &&
                    !this.isShowByTitleID()
                  ) {
                    //Do nothing as we don't want the menu to be visible
                  } else if (!(tsm.menuCode === '/coaching' && isHomeOffice))
                    subMenus.push(tsm);
                }
              }
            }
            if (subMenus.length > 0 || tm.menuType.toUpperCase() !== 'F') {
              tm.subMenus = subMenus;

              // Temp solution for Special Group
              // if (
              // BCP-2477: Hide home button on sidebar for UserGroupID 12
              //   (localStorage.getItem('UserGroupId') === '12' && tm.menuCode === '/home') ||
              //   localStorage.getItem('UserGroupId') !== '12'
              // )
              //   menuSet.push(tm);
              // else if (
              //   (localStorage.getItem('empClass') === 'MERCH') &&
              //   tm.menuCode === '/help' &&
              //   (localStorage.getItem('UserGroupId') === '12')
              // ) {
              //  console.log('here 2');
              //   menuSet.push(tm);
              // }

              if (localStorage.getItem('UserGroupId') !== '12') {
                menuSet.push(tm);
              } else if (localStorage.getItem('UserGroupId') === '12') {
                if (localStorage.getItem('empClass') !== 'MERCH') {
                  menuSet.push(tm);
                } else {
                  if (tm.menuCode !== '/home') {
                    menuSet.push(tm);
                  }
                }
              }
            }
          }
        }
        console.log('Menu Set');
        console.log(menuSet);

        let toState = this.state;
        toState['menus'] = menuSet;
        toState['isLoading'] = false;
        this.setState(toState, () => {
          if (localStorage.getItem('isAdmin') === 'true') {
            let scope = this;
            this.getAdminMenus(scope);
          } else {
          }
        });
      } catch (e) {
        this.setState({ isAuthenticated: false });
        ux.logError(
          'Framework',
          'getMenu',
          'Unable to get menu.',
          'Is authenticated: ' + auth.isAuthenticated(),
        );
        auth.resetUserData();
      }
    });
  }

  async getAdminMenus(scope) {
    if (localStorage.getItem('isAdmin') === 'true') {
      console.log('In Admin Menu');

      let adminMenu = {
        enabled: true,
        menuCode: '/setup',
        MenuIcon: menuIcons['MdSettings'],
        menuLabel: 'Maintenance',
        menuOpenMethod: 'F',
        menuType: 'P',
        pageTitle: '',
        parentId: 0,
        sequence: 9,
      };

      let menuSet = scope.state.menus;
      menuSet.push(adminMenu);
      console.log(menuSet);
      scope.setState({
        menus: menuSet,
      });

      /*await fetch(helper.getAPIHost()+"/api/MenuSetups/3", helper.apiHeaders())
      .then(async response => {
        console.log(response)
        const menuData = await response.json();
        
        let menus = menuData.result;

        if (mMenus.length > 0) {
          let menuSet = scope.state.menus;
          mHeader.subMenus = mMenus;
          menuSet.push(mHeader)
          console.log(menuSet)
          scope.setState({
            menus: menuSet
          });              
        }
      }); */
    }
  }

  handleClick = (name) => () => {
    this.setState((prevState) => {
      const isOpen = prevState[`isOpen${name}`];

      return {
        [`isOpen${name}`]: !isOpen,
      };
    });
  };

  checkIsLogOn() {
    if (auth.isAuthenticated()) {
      this.getMenus();
    } else {
      this.setState({ isAuthenticated: false });
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  componentDidMount() {
    this.checkIsLogOn();

    ux.logAction(
      'EnableTouch',
      'default value',
      helper.getTouch() ? 'Enabled' : 'Disabled',
    );
  }

  isSidebarOpen = () => {
    return this.state.isOpen;
  };

  handleSidebarControlButton = (event) => {
    event.preventDefault();
    event.stopPropagation();

    this.setState(
      (prevState) => ({
        isOpen: !prevState.isOpen,
      }),
      () => {
        // Toggle icons after state update
        if (this.state.isOpen) {
          document.querySelector('.iss-nav-open').style.display = 'none';
          document.querySelector('.iss-nav-close').style.display = 'inline';
        } else {
          document.querySelector('.iss-nav-open').style.display = 'inline';
          document.querySelector('.iss-nav-close').style.display = 'none';
        }
      },
    );
  };

  handleEnableTouch = () => {
    // Check screen width
    ux.logAction(
      'EnableTouch',
      'click',
      helper.getTouch() ? 'Enabled' : 'Disabled',
    );

    this.setState({
      enabledTouch: !helper.touchToggle(),
    });
  };

  handleToggleDrawer = () => {
    this.setState({ openDrawer: !this.state.openDrawer });
  };

  isGuestConnectPermissible = () => {
    const isValidJobTitles = () => {
      const notPermissibleJobTitle = [
        'Bobs Squad Associate',
        'Bobs Squad Associate I',
        'Bobs Squad Associate II',
        'Bobs Squad Director',
        'Bobs Squad National Sales Manager',
        'Online Sales Support Associate',
        'Bobs Squad Online Sales Support Associate',
        'Bobs Squad Online Chat Associate',
        'Business Account Manager',
        'Business Account Executive',
        'Senior Business Account Executive',
        'Director Commercial Sales',
        'Bobs Squad Assistant Manager',
      ];

      if (!notPermissibleJobTitle.includes(localStorage.getItem('JobTitle')))
        return true; //Return true if current JobTitle is NOT in the list
    };

    return (
      helper.getStorelId() !== '1' &&
      helper.getUserGroup() !== 4 &&
      isValidJobTitles()
    );
  };

  render() {
    let bMenus = this.state.menus;
    let iconSize = '38px';
    const { isOpen } = this.state;

    console.log('Main Menu', bMenus);
    if (!this.state.isAuthenticated) {
      return <Redirect to={helper.getLandingPage()} />;
    } else {
      return (
        <aside className={`cr-sidebar ${isOpen ? 'cr-sidebar--open' : ''}`}>
          <div className={bem.e('background')} />
          <div className={bem.e('content')}>
            <Nav vertical>
              <Nav
                navbar
                onClick={this.handleSidebarControlButton}
                className="iss-header-menu-toggle"
              >
                <GiHamburgerMenu
                  size={parseInt(iconSize)}
                  style={{ cursor: 'pointer' }}
                  className="iss-nav-open pngIcon"
                />
                <img
                  src={menuIconsPNG.Close.default}
                  style={{
                    width: iconSize,
                    height: iconSize,
                    cursor: 'pointer',
                    display: 'none',
                  }}
                  className="iss-nav-close pngIcon"
                  alt=""
                />
              </Nav>

              {bMenus.map(
                (
                  {
                    menuCode,
                    menuLabel,
                    menuType,
                    MenuIcon,
                    PNGMenu,
                    pageTitle,
                    subMenus,
                  },
                  index,
                ) => {
                  if (menuType === 'P') {
                    return (
                      <NavItem
                        key={index}
                        className={`${bem.e('nav-item')}`}
                        title={menuLabel}
                      >
                        <BSNavLink
                          id={`navItem-${menuCode}`}
                          tag={NavLink}
                          to={menuCode}
                          activeClassName="active"
                          exact={false}
                        >
                          {PNGMenu && (
                            <img
                              src={PNGMenu}
                              className={bem.e('nav-item-icon')}
                              style={{ width: iconSize, height: iconSize }}
                              alt=""
                            />
                          )}
                          {MenuIcon && (
                            <MenuIcon
                              className={bem.e('nav-item-icon')}
                              style={{ width: iconSize, height: iconSize }}
                            />
                          )}
                          {/* C&C Unacknowledge Badge */}
                          <div
                            id="comm-collab-badge-unack"
                            style={{
                              borderRadius: '50%',
                              backgroundColor: `red`,
                              color: 'white',
                              position: 'fixed',
                              height: '22px',
                              width: '22px',
                              top: '60px',
                              left: '1px',
                              textAlign: 'center',
                              display: 'none',
                              pointerEvents: 'none',
                            }}
                          ></div>
                          {/* C&C Unread Badge */}
                          <div
                            id="comm-collab-badge"
                            style={{
                              borderRadius: '50%',
                              backgroundColor: `#00a8f3`,
                              color: 'white',
                              position: 'fixed',
                              height: '22px',
                              width: '22px',
                              top: '60px',
                              left: '40px',
                              textAlign: 'center',
                              display: 'none',
                              pointerEvents: 'none',
                            }}
                          ></div>

                          <span className="">{menuLabel}</span>
                        </BSNavLink>
                      </NavItem>
                    );
                  } else if (menuType === 'F' && subMenus.length > 0) {
                    return (
                      <div key={`setup${index}`}>
                        <NavItem
                          className={`${bem.e('nav-item')} folder`}
                          title={menuLabel}
                        >
                          <BSNavLink className={bem.e('nav-item-collapse')}>
                            <div className="d-flex">
                              {PNGMenu && (
                                <img
                                  src={PNGMenu}
                                  className={bem.e('nav-item-icon')}
                                  style={{ width: iconSize, height: iconSize }}
                                  alt=""
                                />
                              )}
                              {MenuIcon && (
                                <MenuIcon
                                  className={bem.e('nav-item-icon')}
                                  style={{ width: iconSize, height: iconSize }}
                                />
                              )}
                              <span className=" align-self-start">
                                {menuLabel}
                              </span>
                            </div>
                          </BSNavLink>
                        </NavItem>
                        <Collapse isOpen={true}>
                          {subMenus.map(
                            (
                              {
                                menuCode,
                                menuLabel,
                                menuType,
                                menuIcon,
                                PNGMenu,
                                pageTitle,
                              },
                              index,
                            ) => {
                              let iconClass = '';
                              if (menuCode === '/pipeline') {
                                iconClass = 'menu-pipeline';
                              } else if (menuCode === '/products') {
                                iconClass = 'menu-products';
                              }

                              let MDIcons;
                              if (menuIcon && !PNGMenu) {
                                MDIcons = menuIcons[menuIcon];
                              }

                              return (
                                <NavItem
                                  key={`sub${index})`}
                                  className={bem.e('nav-item')}
                                  title={menuLabel}
                                >
                                  <BSNavLink
                                    id={`navItem-${menuCode}-${index}`}
                                    tag={NavLink}
                                    to={menuCode}
                                    activeClassName="active"
                                    exact={false}
                                    onClick={(e) => {
                                      if (menuCode === '/opportunity') {
                                        e.preventDefault(); // Prevent navigation when menuCode is 'opportunity'
                                        this.handleToggleDrawer();
                                      }
                                    }}
                                  >
                                    {PNGMenu && (
                                      <img
                                        src={PNGMenu}
                                        className={`${bem.e(
                                          'nav-item-icon',
                                        )} ${iconClass}`}
                                        style={{
                                          width: iconSize,
                                          height: iconSize,
                                        }}
                                        alt=""
                                      />
                                    )}

                                    {MDIcons &&
                                    menuCode.replace('/', '') ===
                                      'opportunity' ? (
                                      // <Badge
                                      //   id="side-bar-menu-opportunity-icon-badge"
                                      //   className="opportunity-icon-badge"
                                      //   badgeContent=""
                                      //   anchorOrigin={{
                                      //     vertical: 'top',
                                      //     horizontal: 'left',
                                      //   }}
                                      // >
                                      //   <MDIcons
                                      //     id={`side-bar-menu-opportunity-main-icon}`}
                                      //     className={bem.e('nav-item-icon')}
                                      //     style={{
                                      //       width: iconSize,
                                      //       height: iconSize,
                                      //     }}
                                      //   />
                                      // </Badge>
                                      <div
                                        className="opportunity-circle"
                                        id="side-bar-menu-opportunity-icon-badge"
                                      />
                                    ) : (
                                      MDIcons && (
                                        <MDIcons
                                          id={`side-bar-menu-${menuCode.replace(
                                            '/',
                                            '',
                                          )}`}
                                          className={bem.e('nav-item-icon')}
                                          style={{
                                            width: iconSize,
                                            height: iconSize,
                                          }}
                                        />
                                      )
                                    )}
                                    <span className="">{menuLabel}</span>
                                  </BSNavLink>
                                </NavItem>
                              );
                            },
                          )}
                        </Collapse>
                      </div>
                    );
                  }
                  return index;
                },
              )}

              <NavItem className={`${bem.e('nav-item')}`} title="Touch App">
                <div
                  title="Enable Touch"
                  className="cr-sidebar__nav-item nav-item"
                >
                  <span
                    className={`nav-link ${
                      this.state.enabledTouch ? 'active' : ''
                    }`}
                    id="navItem-/feedback-3"
                    onClick={this.handleEnableTouch}
                  >
                    <img
                      src={menuIconsPNG['Touch'].default}
                      className={bem.e('nav-item-icon')}
                      style={{ width: iconSize, height: iconSize }}
                      alt=""
                    />
                    <span className="">Touch</span>
                  </span>
                </div>
              </NavItem>

              <div
                className="version"
                title={`App Version v${helper.getAPIVersion()}\nWeb Version v${helper.getVersion()}`}
              >
                App v{helper.getAPIVersion()}
                <br />
                Web v{helper.getVersion()}
              </div>
            </Nav>

            {/* Opportunity List/GuestConnect */}

            <OpportunityProvider>
              {this.isGuestConnectPermissible() && this.isShowByTitleID() && (
                <>
                  {/* Keep SignalR connection and notifications always mounted */}
                  <OpportunityListSignalR notificationsOnly={true} />

                  {/* Drawer for UI */}
                  <Drawer
                    id="opportunity-list-drawer"
                    anchor="left"
                    open={this.state.openDrawer}
                    onClose={() => this.handleToggleDrawer()}
                    className="opportunity-list-drawer"
                  >
                    <OpportunityListSignalR notificationsOnly={false} />
                  </Drawer>
                </>
              )}
            </OpportunityProvider>
          </div>
        </aside>
      );
    }
  }
}

export default Sidebar;
