import React, { useState } from 'react';
import { Box, IconButton, Paper, Typography } from '@mui/material';

// Components
import ProductChatbot from './product-chatbot';
import KnowledgeBaseChatbot from './knowledge-base';

// Icons
import {
  FaCog,
  FaCompressArrowsAlt,
  FaExpandArrowsAlt,
  FaTimes,
} from 'react-icons/fa';
import { BsChatDotsFill } from 'react-icons/bs';
import { FaRobot, FaSearch } from 'react-icons/fa';
import { GoArrowSwitch } from 'react-icons/go';

// Styles
import './chatbot.scss';

const chatbots = {
  product: 'Product Assistant',
  knowledgebase: 'Knowledge Base',
};

/**
 * A container component for the chatbot. It handles the rendering of the chatbot
 * header, body, and footer. It also handles the state of the chatbot, such as
 * whether it is open or closed, and whether it is in fullscreen mode or not.
 *
 * The component accepts the following props:
 *
 * - `open`: a boolean indicating whether the chatbot is open or not.
 * - `onClose`: a function to be called when the user closes the chatbot.
 *
 * The component renders the following elements:
 *
 * - a header with the chatbot title and a button to switch between the two
 *   chatbots.
 * - a body that renders the chatbot UI based on the current state of the
 *   chatbot.
 * - a footer with a button to close the chatbot.
 *
 * The component also handles the state of the chatbot, such as whether it is
 * open or closed, and whether it is in fullscreen mode or not.
 *
 * The component is a controlled component, meaning that the parent component
 * is responsible for managing the state of the chatbot.
 */
const ChatbotContainer = ({ open, onClose }) => {
  const [isFullscreen, setIsFullscreen] = useState(false); // State for fullscreen mode
  const [activeChatbot, setActiveChatbot] = useState(null); // Default to product chatbot
  const [showChatPrompt, setShowChatPrompt] = useState(false); // Add state for chat prompt
  const [showSettings, setShowSettings] = useState(); // Add state for settings

  const handleClose = () => {
    setActiveChatbot(null);
    setIsFullscreen(false);
    onClose();
  };

  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
  };

  const renderHeader = () => {
    return (
      <Box className="chat-header">
        <Typography variant="h6">
          {activeChatbot === chatbots.product
            ? chatbots.product
            : chatbots.knowledgebase}

          <IconButton onClick={() => setActiveChatbot(null)}>
            <GoArrowSwitch title="Switch Chatbot" />
          </IconButton>
        </Typography>

        <div>
          {/* Expand/Minimize Icon */}
          <IconButton onClick={toggleFullscreen} className="expand-button">
            {isFullscreen ? (
              <FaCompressArrowsAlt title="Minimize" />
            ) : (
              <FaExpandArrowsAlt title="Expand" />
            )}
          </IconButton>

          {activeChatbot === chatbots.product && (
            <>
              {/* Open Chat Prompts */}
              <IconButton
                onClick={() => setShowChatPrompt(!showChatPrompt)}
                className="manage-button"
              >
                <BsChatDotsFill title="Chat Prompts" />
              </IconButton>

              {/* Setting Icon */}
              <IconButton
                onClick={(e) =>
                  showSettings
                    ? setShowSettings(null)
                    : setShowSettings(e.currentTarget)
                }
                className="manage-button"
              >
                <FaCog title="Settings" />
              </IconButton>
            </>
          )}

          {/* Close Icon */}
          <IconButton onClick={handleClose} className="close-button">
            <FaTimes title="Close" />
          </IconButton>
        </div>
      </Box>
    );
  };

  const renderChatbot = () => {
    // Common styles moved outside the render for better performance
    const styles = {
      container: {
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
        padding: 4,
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        backgroundColor: '#f8f9fa',
        backgroundImage: 'radial-gradient(#e9ecef 1px, transparent 1px)',
        backgroundSize: '20px 20px',
      },
      header: {
        mb: 2,
        fontWeight: 600,
        color: '#2d3436',
      },
      subHeader: {
        mb: 2,
        color: '#6c757d',
      },
      cardsContainer: {
        display: 'flex',
        gap: 4,
        justifyContent: 'center',
        width: '100%',
      },
      cardBase: {
        minWidth: 200,
        minHeight: 350,
        maxWidth: 350,
        padding: '24px',
        cursor: 'pointer',
        transition: 'all 0.3s ease',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        backgroundColor: 'white',
        position: 'relative',
        overflow: 'hidden',
        '&:hover': {
          transform: 'translateY(-8px)',
          boxShadow: '0 12px 24px rgba(0,0,0,0.15)',
          '& .icon-box': {
            transform: 'scale(1.1)',
          },
        },
      },
      iconBase: {
        width: 80,
        height: 80,
        borderRadius: '16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '20px',
        color: 'white',
        transition: 'transform 0.3s ease',
        className: 'icon-box',
      },
      cardText: {
        color: '#636e72',
        lineHeight: 1.6,
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        maxWidth: '85%',
      },
    };

    const chatbotConfig = {
      [chatbots.product]: {
        Icon: FaRobot,
        title: chatbots.product,
        description: 'Get tailored product recommendations and insights',
        gradient: 'linear-gradient(135deg, #d0281b 0%, #ff6b6b 100%)',
      },
      [chatbots.knowledgebase]: {
        Icon: FaSearch,
        title: chatbots.knowledgebase,
        description:
          'Search and explore our comprehensive documentation with ease',
        gradient: 'linear-gradient(135deg, #2196f3 0%, #64b5f6 100%)',
      },
    };

    const renderCard = (type) => {
      const config = chatbotConfig[type];
      return (
        <Paper
          elevation={3}
          sx={{
            ...styles.cardBase,
            '&::before': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              height: '4px',
              background: config.gradient,
              opacity: 0.7,
            },
          }}
          onClick={() => setActiveChatbot(type)}
        >
          <Box
            className="icon-box"
            sx={{ ...styles.iconBase, background: config.gradient }}
          >
            <config.Icon style={{ fontSize: '42px' }} />
          </Box>
          <Typography variant="h5" sx={styles.header}>
            {config.title}
          </Typography>
          <Typography variant="body1" sx={styles.cardText}>
            {config.description}
          </Typography>
        </Paper>
      );
    };

    switch (activeChatbot) {
      case chatbots.product:
        return (
          <ProductChatbot
            isFullscreen={isFullscreen}
            showChatPrompt={showChatPrompt}
            setShowChatPrompt={setShowChatPrompt}
            showSettings={showSettings}
            setShowSettings={(event) =>
              showSettings
                ? setShowSettings(null)
                : setShowSettings(event.currentTarget)
            }
          />
        );
      case chatbots.knowledgebase:
        return <KnowledgeBaseChatbot isFullscreen={isFullscreen} />;
      default:
        return (
          <Box sx={styles.container}>
            <Box>
              <Typography variant="h4" sx={styles.header}>
                We're here to help!
              </Typography>
              <Typography variant="body1" sx={styles.subHeader}>
                How can we assist you today?
              </Typography>
            </Box>
            <Box sx={styles.cardsContainer}>
              {renderCard(chatbots.product)}
              {renderCard(chatbots.knowledgebase)}
            </Box>
          </Box>
        );
    }
  };

  return (
    <Paper
      elevation={3}
      className={`chat-window ${open ? 'open' : ''} ${
        isFullscreen ? 'full-screen' : ''
      }`}
    >
      {activeChatbot && renderHeader()}
      {renderChatbot()}
    </Paper>
  );
};

export default ChatbotContainer;
