import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
} from 'react';

//Component
import OpportunityList from '.';

//Enums
import enums from '../../enums';

//Context
import { OpportunityContext } from './opportunity-context';
import SignalRContext from '../../application-center/signalr-hub-center/singalr-context';
import { refreshNotepad } from './opportunity-notepad';
import { beginOpportunityActions } from './opportunity-modal';

//Services
import pushNotificationService from '../../services/pushNotificationService';

//Helpers
const helper = require('../../utils/helper');

export const OpportunityListSignalR = ({ notificationsOnly = false }) => {
  const [connection, setConnection] = useState(null);
  const [firstConnection, setFirstConnection] = useState(null);
  const [queueAssociate, setQueueAssociate] = useState([]);
  const [engagingAssociate, setEngagingAssociate] = useState([]);
  const [connectionConnected, setConnectionConnected] = useState(false);
  const [associateInfoStack, setAssociateInfoStack] = useState({});
  const [checkInEmpIdRef, setCheckInEmpIdRef] = useState('');
  const [dateTimeData, setdateTimeData] = useState('');
  const [questions, setQuestions] = useState([]);

  const mainConnection = useContext(SignalRContext);
  const {
    currentUserInformation,
    notepadInfo,
    updateNotepadInfo,
    updateTwoMinDrillEmpId,
  } = useContext(OpportunityContext);

  const latestQueue = useRef(null);
  const latestEngaging = useRef(null);
  const notepadRef = useRef();

  const firstJoinTimer = useRef(null);

  latestQueue.current = queueAssociate;
  latestEngaging.current = engagingAssociate;

  // Memoized ordinal suffix helper
  const getOrdinalSuffix = useCallback((position) => {
    // Ensure we're working with a positive integer
    const num = Math.floor(Math.abs(position));

    if (num < 1) return '';

    const tens = num % 100;

    // Special case for 11-13
    if (tens >= 11 && tens <= 13) return 'th';

    const ones = num % 10;
    switch (ones) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  }, []);

  const getCurrentUserPosition = useCallback(
    (
      empId = currentUserInformation?.empId,
      showOrdinal = false,
      queueFloor = [],
      excludeGhost = false,
    ) => {
      // Early return if no valid empId
      if (!empId) return showOrdinal ? '' : -1;

      // Use provided queue or fallback to current queue
      const queue = queueFloor?.length > 0 ? queueFloor : queueAssociate;

      // Early return if no queue
      if (!queue?.length) return showOrdinal ? '' : -1;

      // Find position with single array iteration
      const position = queue.reduce((pos, user, index) => {
        if (pos > -1) return pos; // Already found
        if (!user.isActive) return pos; // Skip inactive
        if (excludeGhost && user.userAction === 'Ghost') return pos; // Skip ghost if excluded
        return user.employeeID === empId ? index + 1 : pos;
      }, -1);

      // Return formatted result
      return showOrdinal && position > 0
        ? `${position}${getOrdinalSuffix(position)}`
        : position;
    },
    [currentUserInformation?.empId, queueAssociate, getOrdinalSuffix],
  );

  // Notification state management
  const [notificationState, setNotificationState] = useState({
    notifiedPositions: new Set(),
    lastNotificationTime: null,
    isInitialJoin: true,
    wasInQueue: false,
    wasEngaging: false,
    initialPosition: null,
  });

  // Reset notification state
  const resetNotificationState = useCallback(() => {
    if (firstJoinTimer.current) {
      clearTimeout(firstJoinTimer.current);
      firstJoinTimer.current = null;
    }
    setNotificationState({
      notifiedPositions: new Set(),
      lastNotificationTime: null,
      isInitialJoin: true,
      wasInQueue: false,
      wasEngaging: false,
      initialPosition: null,
    });
  }, []);

  // Handle notifications
  const handleNotification = useCallback((position) => {
    setNotificationState((prev) => {
      const newNotifiedPositions = new Set(prev.notifiedPositions);
      newNotifiedPositions.add(position);
      return {
        ...prev,
        notifiedPositions: newNotifiedPositions,
        lastNotificationTime: Date.now(),
      };
    });
    pushNotificationService.notifyGuestConnectPositionChange(position);
  }, []);

  useEffect(() => {
    const connection = mainConnection; // Access the connection from the reference context

    if (connection && connection._connectionStarted) {
      setConnection(connection);
      setConnectionConnected(connection._connectionStarted);
      console.log('Connected!', connection);

      //Update Whole Floors for first connection
      connection.on('RetrieveQueue', (queue) => {
        //Change DateTime to local
        queue = queue.map((x) => (x = convertUTCToLocalTime(x)));

        const queueFloor = [...queue.filter((x) => x.floorType === 'QUEUE')];
        const engagingFloor = [
          ...queue.filter((x) => x.floorType === 'ENGAGE'),
        ];

        const queueFloor1 = Object.values(
          queueFloor.reduce((acc, item) => {
            const { employeeID } = item;

            if (!acc[employeeID]) {
              acc[employeeID] = { ...item, stackData: [] };
            }

            if (acc[employeeID].stackData.length > 0) {
              return acc;
            }

            acc[employeeID].stackData.push({ ...item });

            return acc;
          }, {}),
        );

        queueFloor1.sort((a, b) => {
          if (a.floorSeq === null && b.floorSeq === null) {
            return 0; // If both are null, maintain the current order
          } else if (a.floorSeq === null) {
            return 1; // Put null values at the bottom
          } else if (b.floorSeq === null) {
            return -1; // Put null values at the bottom
          } else {
            return a.floorSeq - b.floorSeq; // Sort non-null values normally
          }
        });

        const engagingFloor1 = Object.values(
          engagingFloor.reduce((acc, item) => {
            const { employeeID } = item;

            if (!acc[employeeID]) {
              acc[employeeID] = { ...item, stackData: [] };
            }

            acc[employeeID].stackData.push({ ...item });

            return acc;
          }, {}),
        );

        console.log('Retrieve Opportunity', queue, queueFloor1, engagingFloor1);

        setQueueAssociate(queueFloor1);
        setEngagingAssociate(engagingFloor1);

        setQueueBadgeNumberOnHeader([...queueFloor1], [...engagingFloor1]);
      });

      connection.on('ManagerCheckIn', (isCurrentlyInSession, employeeId) => {
        const checkInDateTime = new Date().toISOString();

        setCheckInEmpIdRef(employeeId);
        if (employeeId) updateTwoMinDrillEmpId(employeeId); //Use for Coaching 2 min-drill
        setdateTimeData(checkInDateTime);
      });

      //Refresh notepad
      connection.on('RefreshNotepad', (notepad) => {
        if (
          notepad.version === 0 &&
          notepad.opportunity === null &&
          notepad.storeId === null
        ) {
          //If all session is new, then initialize
          let notepadInfo = {
            version: 0,
            updatedTime: new Date(),
            opportunity: [],
            storeId: currentUserInformation.storeId,
          };
          updateNotepadInfo(notepadInfo);
          console.log('Notepad Initialized', notepad);
        } else if (notepad.storeId === currentUserInformation.storeId) {
          updateNotepadInfo(notepad);
          console.log('Notepad Refreshed', notepad);
        }
      });

      connection.on('StartNotepad', (data) => {
        refreshNotepad(notepadRef.current);
      });

      //(NOT USING FROM API)
      //(NOT USING FROM API)
      //(NOT USING FROM API)
      //Update Queue Floor
      connection.on('JoinOpportunity', (queue) => {
        //Change DateTime to local
        queue = convertUTCToLocalTime(queue);

        const updatedQueue = [...latestQueue.current];
        updatedQueue.push(queue);

        console.log('Join Opportunity', queue, updatedQueue);

        setQueueAssociate(updatedQueue);
      });

      //(NOT USING FROM API)
      //(NOT USING FROM API)
      //(NOT USING FROM API)
      //Update Engaging Floor
      connection.on('EngageOpportunity', (queue) => {
        //Change DateTime to local
        queue = convertUTCToLocalTime(queue);

        const updatedQueue = [...latestEngaging.current];
        updatedQueue.push(queue);

        console.log('Engage Opportunity', queue, updatedQueue);

        setEngagingAssociate(updatedQueue);

        //Remove from Queue Floor
        const toUpdateQueue = [...latestQueue.current];
        let newQueue = toUpdateQueue.filter(
          (x) => x.employeeID !== queue.employeeID,
        );
        setQueueAssociate(newQueue);
      });

      //(NOT USING FROM API)
      //(NOT USING FROM API)
      //(NOT USING FROM API)
      //Update Engaging Floor by Disengage action
      connection.on('DisengageOpportunity', (queue) => {
        //Change DateTime to local
        queue = convertUTCToLocalTime(queue);

        const updatedQueue = [...latestEngaging.current];
        const newQueue = updatedQueue.filter(
          (x) => x.employeeID !== queue.employeeID,
        );

        console.log('Disengage Opportunity', queue, newQueue);

        setEngagingAssociate(newQueue);
      });

      setTimeout(() => {
        setFirstConnection(true);
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainConnection]); // Add connectionRef to the dependencies array

  useEffect(() => {
    if (firstConnection) {
      RetrieveOpportunity();

      if (currentUserInformation.isManager) {
        RefreshManagerCheckin();
      }

      setFirstConnection(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstConnection]);

  useEffect(() => {
    notepadRef.current = notepadInfo;
  }, [notepadInfo]);

  useEffect(() => {
    if (!connection || !currentUserInformation) {
      console.log('Early return - missing connection or user:', {
        connection,
        currentUserInformation,
      });
      return;
    }

    const currentPosition = getCurrentUserPosition(
      currentUserInformation.empId,
      false,
      queueAssociate,
      true,
    );

    const isEngaging = engagingAssociate?.some(
      (x) => x.employeeID === currentUserInformation.empId,
    );
    const isCurrentlyInQueue = currentPosition > 0;
    const isActive = isCurrentlyInQueue || isEngaging;

    console.log('Current State:', {
      currentPosition,
      isEngaging,
      isCurrentlyInQueue,
      isActive,
      queueLength: queueAssociate?.length,
      notificationState,
    });

    // Handle inactive state
    if (!isActive) {
      console.log('Resetting - user not active');
      resetNotificationState();
      return;
    }

    // Handle state transitions
    const hasStateChanged =
      isCurrentlyInQueue !== notificationState.wasInQueue ||
      isEngaging !== notificationState.wasEngaging;

    if (hasStateChanged) {
      console.log('State changed:', {
        from: {
          wasInQueue: notificationState.wasInQueue,
          wasEngaging: notificationState.wasEngaging,
        },
        to: {
          isCurrentlyInQueue,
          isEngaging,
        },
      });

      setNotificationState((prev) => {
        const updates = {
          wasInQueue: isCurrentlyInQueue,
          wasEngaging: isEngaging,
        };

        // Reset position on new queue entry
        if (isCurrentlyInQueue && (!prev.wasInQueue || prev.wasEngaging)) {
          updates.initialPosition = currentPosition;
          updates.isInitialJoin = true;
          console.log('Setting initial position:', currentPosition);
        }

        return { ...prev, ...updates };
      });
    }

    // Handle notifications
    if (isCurrentlyInQueue && queueAssociate?.length > 0) {
      // Skip notifications if this is first time joining queue
      const isFirstTimeJoining =
        !notificationState.wasInQueue && !notificationState.wasEngaging;

      if (isFirstTimeJoining) {
        console.log('First time joining queue - setting 2s debounce timer');

        // Clear any existing timer
        if (firstJoinTimer.current) {
          clearTimeout(firstJoinTimer.current);
        }

        // Set new timer
        firstJoinTimer.current = setTimeout(() => {
          setNotificationState((prev) => ({
            ...prev,
            wasInQueue: isCurrentlyInQueue,
            wasEngaging: isEngaging,
            initialPosition: currentPosition,
          }));
          firstJoinTimer.current = null;
        }, 2000);

        return;
      }

      const notificationChecks = {
        isPosition1or2: currentPosition === 1 || currentPosition === 2,
        notInitialJoin: !notificationState.isInitialJoin,
        hasInitialPosition: notificationState.initialPosition !== null,
        // Only require position improvement if initial position was worse than 1-2
        isValidPositionChange:
          notificationState.initialPosition > 2
            ? currentPosition < notificationState.initialPosition // Must improve if started > 2
            : currentPosition <= notificationState.initialPosition, // Can stay same if started ≤ 2
        notAlreadyNotified:
          !notificationState.notifiedPositions.has(currentPosition),
      };

      console.log('Notification Checks:', {
        ...notificationChecks,
        currentPosition,
        initialPosition: notificationState.initialPosition,
        wasEngaging: notificationState.wasEngaging,
        isFirstTimeJoining,
      });

      const shouldNotify = Object.values(notificationChecks).every(Boolean);

      if (shouldNotify) {
        // console.log('Sending notification for position:', currentPosition);
        handleNotification(currentPosition);
      }

      // Update initial join state
      if (notificationState.isInitialJoin) {
        // console.log('Setting initial join to false');
        setNotificationState((prev) => ({ ...prev, isInitialJoin: false }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queueAssociate, engagingAssociate]);

  // Debug logging
  useEffect(() => {
    console.log('Notification State:', {
      ...notificationState,
      notifiedPositions: Array.from(notificationState.notifiedPositions),
    });
  }, [notificationState]);

  const setQueueBadgeNumberOnHeader = (queueFloor, engagingFloor) => {
    const resetSideBarBagdeElement = () => {
      //Remove class for side-bar-menu-opportunity
      let sideBarBadge = getSideBarBadgeElement();
      if (sideBarBadge) {
        sideBarBadge.classList.remove('yellow-badge');
        sideBarBadge.classList.remove('green-badge');
        sideBarBadge.classList.remove('red-badge');
        sideBarBadge.innerHTML = '';
      }
    };

    const getSideBarBadgeElement = () => {
      return document.getElementById('side-bar-menu-opportunity-icon-badge');
    };

    let currentUserDataIndex = getCurrentUserPosition(
      currentUserInformation.empId,
      false,
      queueFloor,
    );

    currentUserDataIndex = currentUserDataIndex--; // -1 since we've already +1 in getCurrentUserPosition for display

    let currentUserData = queueFloor.find(
      (x) => x.employeeID === currentUserInformation.empId,
    );

    let badgeElement = document.getElementById('opportunity-list-badge');
    if (
      badgeElement &&
      currentUserDataIndex !== -1 &&
      currentUserData.isActive
    ) {
      if (
        window.getComputedStyle(badgeElement).getPropertyValue('display') ===
        'none'
      ) {
        // If it's not visible, set display to "block"
        badgeElement.style.display = 'block';
      }

      //Set the queue number
      badgeElement.innerHTML = currentUserDataIndex;
    } else if (currentUserDataIndex === -1 || !currentUserData.isActive) {
      // Set display to none if no current user data
      badgeElement.style.display = 'none';
    }

    //Side bar badge element
    if (getSideBarBadgeElement()) {
      //Set the class for side-bar-menu-opportunity-main-icon
      resetSideBarBagdeElement();
      let sideBarBadgeElement = getSideBarBadgeElement();

      if (sideBarBadgeElement) {
        let currentQueuePosition = currentUserDataIndex;
        let inSession = engagingFloor.some(
          (x) => x.employeeID === currentUserInformation.empId,
        );

        if (currentUserData && !currentUserData.isActive) {
          //Color yellow in the list for inactive
          sideBarBadgeElement.classList.add('yellow-badge');
          sideBarBadgeElement.innerHTML = '';
        } else if (currentUserData && currentQueuePosition !== -1) {
          //Color green in the list
          sideBarBadgeElement.classList.add('green-badge');
          sideBarBadgeElement.innerHTML = currentQueuePosition;
        } else if (inSession) {
          //Color red in the list for not in queue
          sideBarBadgeElement.classList.add('red-badge');
          sideBarBadgeElement.innerHTML = '';
        }
      }
    }
  };

  const convertUTCToLocalTime = (obj) => {
    let data = { ...obj };
    // Convert UTC datetime to local time
    const connectedTime = data.connectedTime
      ? new Date(data.connectedTime + 'Z').toLocaleString()
      : data.connectedTime;

    const startQueue = data.startQueue
      ? new Date(data.startQueue.replace('Z', '') + 'Z').toLocaleString()
      : data.startQueue;

    const startAction = data.startAction
      ? new Date(data.startAction.replace('Z', '') + 'Z').toLocaleString()
      : data.startAction;

    const startQueueFirstPosition = data.startQueueFirstPosition
      ? new Date(
          data.startQueueFirstPosition.replace('Z', '') + 'Z',
        ).toLocaleString()
      : data.startQueueFirstPosition;

    data.connectedTime = connectedTime;
    data.startQueue = startQueue;
    data.startAction = startAction;
    data.startQueueFirstPosition = startQueueFirstPosition;

    return { ...data };
  };

  //Method to check if associate already joined in the list
  const AssociateJoinedQueue = () => {
    if (
      queueAssociate.filter(
        (x) => x.employeeID === currentUserInformation.empId,
      ).length > 0 ||
      engagingAssociate.filter(
        (x) => x.employeeID === currentUserInformation.empId,
      ).length > 0
    ) {
      return true;
    }
    return false;
  };

  const AssociateCurrentlyEngaged = () => {
    if (
      engagingAssociate.filter(
        (x) => x.employeeID === currentUserInformation.empId,
      ).length > 0
    ) {
      return true;
    }
    return false;
  };

  const RefreshManagerCheckin = async () => {
    if (connectionConnected) {
      try {
        await fetch(
          `${helper.getSignalRHost()}/api/OpportunityList/RefreshManagerCheckIn?ManagerId=${
            currentUserInformation.empId
          }`,
          {
            method: 'POST',
            ...helper.apiHeaders(),
          },
        );
      } catch (e) {
        console.log('Sending message failed.', e);
      }
    } else {
      alert(
        'No connection to the server has been established to retrieve.',
        enums.notificationType.W,
      );
    }
  };

  const RetrieveOpportunity = async () => {
    if (connectionConnected) {
      try {
        try {
          await fetch(
            helper.getSignalRHost() + '/api/OpportunityList/RetrieveQueue',
            {
              method: 'POST',
              ...helper.apiHeaders(),
            },
          );
        } catch (e) {
          console.log('Sending message failed.', e);
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      alert(
        'No connection to the server has been established to retrieve.',
        enums.notificationType.W,
      );
    }
  };

  const JoinOpportunity = async () => {
    if (connectionConnected) {
      try {
        try {
          //Do not allow to join queue if employee id already exist in Queue Floor or Engaging Floor
          if (AssociateJoinedQueue()) {
            return;
          }

          await fetch(
            helper.getSignalRHost() + '/api/OpportunityList/JoinOpportunity',
            {
              method: 'POST',
              ...helper.apiHeaders(),
            },
          ).then(() => {
            if (
              currentUserInformation.isManager &&
              !currentUserInformation.isSnrGXS &&
              !currentUserInformation.isAssociate
            ) {
              EngageOpportunity(
                currentUserInformation.empId,
                beginOpportunityActions.unattendedGuest,
              );
            }
          });
        } catch (e) {
          console.log('Sending message failed.', e);
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      alert(
        'No connection to the server has been established.',
        enums.notificationType.W,
      );
    }
  };

  const ExitOpportunity = async (EmployeeId) => {
    if (connectionConnected) {
      try {
        try {
          await fetch(
            helper.getSignalRHost() +
              `/api/OpportunityList/ExitOpportunity${
                EmployeeId ? '?EmployeeId=' + EmployeeId : ''
              }`,
            {
              method: 'POST',
              ...helper.apiHeaders(),
            },
          );

          //Remove notepad entry
          let notepadToUpdate = notepadInfo.opportunity;
          if (notepadToUpdate && notepadToUpdate.length > 0) {
            notepadInfo.opportunity = notepadToUpdate?.filter(
              (x) => x.employeeId !== currentUserInformation.empId,
            );
            notepadInfo.version++;
            refreshNotepad(notepadInfo);
          }
        } catch (e) {
          console.log('Sending message failed.', e);
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      alert(
        'No connection to the server has been established.',
        enums.notificationType.W,
      );
    }
  };

  const EngageOpportunity = async (EmployeeId, action, uid, WalkInEnable) => {
    if (connectionConnected) {
      try {
        try {
          await fetch(
            helper.getSignalRHost() + `/api/OpportunityList/EngageOpportunity`,
            {
              method: 'POST',
              body: JSON.stringify({
                Uid: uid || '',
                EmployeeId: EmployeeId,
                action: action,
                WalkInEnable: WalkInEnable || false,
              }),
              ...helper.apiHeaders(),
            },
          ).then(async (response) => {
            let data = await response.json();
            setAssociateInfoStack(data.result);
          });
        } catch (e) {
          console.log('Sending message failed.', e);
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      alert(
        'No connection to the server has been established.',
        enums.notificationType.W,
      );
    }
  };

  const DisengageOpportunity = async (
    uid,
    EmployeeId,
    action,
    shouldTotalRemove = false,
    opportunityTicket,
  ) => {
    if (connectionConnected) {
      try {
        await fetch(
          helper.getSignalRHost() + '/api/OpportunityList/DisengageOpportunity',
          {
            method: 'POST',
            body: JSON.stringify({
              uid: uid || '',
              EmployeeId: EmployeeId,
              action: action,
            }),
            ...helper.apiHeaders(),
          },
        )
          .then(async () => {
            if (shouldTotalRemove) await ExitOpportunity(EmployeeId);

            if (opportunityTicket) {
              const url =
                helper.getAPIHost() +
                `/api/OpportunityList/GetOpportunitySurvey?opportunityTicket=${opportunityTicket}`;
              return fetch(url, {
                method: 'GET',
                ...helper.apiHeaders(),
              }).then(async (response) => {
                let data = await response.json();
                if (data.status === 'OK' && data.result) {
                  console.log('Survey questions:', data.result);
                  setQuestions(data.result);
                  console.log('Updated Questions state:', data.result);
                } else {
                  console.error('Error in API response:', data.errorMsg);
                }
              });
            }
          })

          .catch((e) => {
            console.log('Sending message failed.', e);
          });

        // Remove notepad entry
        let notepadToUpdate = notepadInfo.opportunity;
        if (notepadToUpdate && notepadToUpdate.length > 0) {
          notepadInfo.opportunity = notepadToUpdate?.filter(
            (x) => x.uid !== uid,
          );
          notepadInfo.version++;
          refreshNotepad(notepadInfo);
        }
      } catch (e) {
        console.log('Sending message failed.', e);
      }
    } else {
      alert(
        'No connection to the server has been established.',
        enums.notificationType.W,
      );
    }
  };

  const ChangeOpportunitySequence = async (EmployeeId, toAscending) => {
    if (connectionConnected) {
      try {
        try {
          await fetch(
            helper.getSignalRHost() +
              `/api/OpportunityList/ChangeOpportunitySequece`,
            {
              method: 'POST',
              body: JSON.stringify({
                EmployeeId: EmployeeId,
                toAscending: toAscending,
              }),
              ...helper.apiHeaders(),
            },
          );
        } catch (e) {
          console.log('Sending message failed.', e);
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      alert(
        'No connection to the server has been established.',
        enums.notificationType.W,
      );
    }
  };

  const ChangeOpportunityStatus = async (EmployeeId, action) => {
    if (connectionConnected) {
      try {
        try {
          await fetch(
            helper.getSignalRHost() +
              '/api/OpportunityList/ChangeOpportunityStatus',
            {
              method: 'POST',
              body: JSON.stringify({ EmployeeId: EmployeeId, action: action }),
              ...helper.apiHeaders(),
            },
          );
        } catch (e) {
          console.log('Sending message failed.', e);
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      alert(
        'No connection to the server has been established.',
        enums.notificationType.W,
      );
    }
  };

  if (notificationsOnly) {
    return null;
  }

  return (
    <OpportunityList
      connection={connection}
      connectionConnected={connectionConnected}
      associateJoinedQueue={AssociateJoinedQueue}
      associateCurrentlyEngaged={AssociateCurrentlyEngaged}
      joinOpportunity={JoinOpportunity}
      exitOpportunity={ExitOpportunity}
      engageOpportunity={EngageOpportunity}
      disengageOpportunity={DisengageOpportunity}
      changeOpportunitySequence={ChangeOpportunitySequence}
      changeOpportunityStatus={ChangeOpportunityStatus}
      queueAssociate={queueAssociate}
      engagingAssociate={engagingAssociate}
      associateInfoStack={associateInfoStack}
      getCurrentUserPosition={getCurrentUserPosition}
      checkInEmpIdRef={checkInEmpIdRef}
      dateTimeData={dateTimeData}
      questions={questions}
    />
  );
};
